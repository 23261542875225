var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trends-recommend"},[_c('div',{staticClass:"center-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keywords),expression:"keywords"}],attrs:{"type":"text","placeholder":"keyword"},domProps:{"value":(_vm.keywords)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.seachEnterFun($event)},"input":function($event){if($event.target.composing){ return; }_vm.keywords=$event.target.value}}}),_c('button',{on:{"click":_vm.chick}},[_vm._v("search")])]),(_vm.list.length >= 1)?_c('div',{staticClass:"recom-center"},[_vm._l((_vm.list),function(i,trIndex){return _c('ul',{key:i.id,staticClass:"ul1"},[_c('li',{staticClass:"li1"},[_c('div',{staticClass:"center-img",class:{
            'news-name': true,
            color: trIndex != 0,
          }},[_c('router-link',{staticClass:"product-about",attrs:{"target":"_blank","tag":"a","to":{
              name: 'ProDetail',
              params: {
                id: _vm.id,
                infoId: i.id,
              },
            }}},[_c('p',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.axios.defaults.baseURL + i.coverImg,"alt":""}})]),_c('div',{staticClass:"center-name"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(i.title))]),_c('p',{staticClass:"prize"},[_vm._v(_vm._s(i.introduce))]),_c('p',{staticClass:"center-more"},[_vm._v(" more"),_c('img',{attrs:{"src":require("../../assets/img/moretwo.png"),"alt":""}})]),_c('p')]),_c('p',{staticClass:"date"},[_vm._v(_vm._s(i.releaseTime))])])],1)])])}),_c('div',{staticClass:"page-bar"},[(_vm.all != 0)?_c('ul',[(_vm.cur > 1)?_c('li',[_c('a',{on:{"click":function($event){_vm.cur--, _vm.pageClick()}}},[_vm._v("上一页")])]):_vm._e(),_vm._l((_vm.indexs),function(index){return _c('li',{key:index,class:{ active: _vm.cur == index }},[_c('a',{on:{"click":function($event){return _vm.btnClick(index)}}},[_vm._v(_vm._s(index))])])}),(_vm.cur != _vm.all)?_c('li',[_c('a',{on:{"click":function($event){_vm.cur++, _vm.pageClick()}}},[_vm._v("下一页")])]):_vm._e()],2):_c('ul',[_c('li',{staticClass:"page-barson"},[_vm._v("暂时没有更多数据了")])])])],2):_c('div',{staticClass:"page-barson"},[_vm._v("暂时没有更多数据了")])])}
var staticRenderFns = []

export { render, staticRenderFns }