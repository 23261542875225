<template>
  <div class="trends-recommend">
    <div class="center-search">
      <!-- <p class="search-name">keyword:</p> -->
      <input
        type="text"
        placeholder="keyword"
        v-model="keywords"
        @keyup.enter="seachEnterFun"
      />
      <button @click="chick">search</button>
    </div>
    <div class="recom-center" v-if="list.length >= 1">
      <ul v-for="(i, trIndex) in list" :key="i.id" class="ul1">
        <li class="li1">
          <div
            class="center-img"
            :class="{
              'news-name': true,
              color: trIndex != 0,
            }"
          >
            <router-link
              class="product-about"
              target='_blank'
                tag="a"
              :to="{
                name: 'ProDetail',
                params: {
                  id: id,
                  infoId: i.id,
                },
              }"
            >
              <p class="image">
                <img :src="axios.defaults.baseURL + i.coverImg" alt="" />
              </p>
              <div class="center-name">
                <p class="name">{{ i.title }}</p>
                <p class="prize">{{ i.introduce }}</p>
                <p class="center-more">
                  more<img src="../../assets/img/moretwo.png" alt="" />
                </p>

                <p></p>
              </div>
              <p class="date">{{ i.releaseTime }}</p>
            </router-link>
          </div>
        </li>
      </ul>

      <div class="page-bar">
        <ul v-if="all != 0">
          <li v-if="cur > 1"><a v-on:click="cur--, pageClick()">上一页</a></li>
          <li
            v-for="index in indexs"
            :class="{ active: cur == index }"
            :key="index"
          >
            <a v-on:click="btnClick(index)">{{ index }}</a>
          </li>
          <li v-if="cur != all">
            <a v-on:click="cur++, pageClick()">下一页</a>
          </li>
        </ul>
        <ul v-else>
          <li class="page-barson">暂时没有更多数据了</li>
        </ul>
      </div>
    </div>
    <!-- <div
      class="dedetail"
      v-else-if="
        list.length <= 1 && detailList != undefined && detailList != ''
      "
    >
      <div class="newname">{{ detailList.title }}</div>
      <iframe
        style="position：absolute"
        id="particular"
        frameborder="0"
        width="100%"
        height="100%"
        scrolling="no"
        @load="getDetail"
      >
      </iframe>
    </div> -->
    <div v-else class="page-barson">暂时没有更多数据了</div>
  </div>
</template>

<script>
var oldh;
oldh = 0;
export default {
  // 接收数组对象
  data() {
    return {
      list: [],
      keywords: "", //搜索默认为空
      detailList: [], //当条数为1数据
      all: "", //总页数
      cur: 1, //当前页码
      count: 0, //总条数
      id: null, //页面id
      detailId: null, //详情id
      aa: "",
      //  coverImg: "",
      // trendspageImg: {
      //   backgroundImage: "",
      // },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getTreList(); //生命周期创建时调用axios函数
    if (this.$route.params) {
      this.id = this.$route.params.id;
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.params.id != from.params.id) {
        this.id = to.params.id;
        location.reload();
      }
    },
    keywords: function (val) {
      this.keywords = val;
      if (val == "") {
        this.getTreList();
      } else {
        this.seachEnterFun;
      }
    },
  },
  methods: {
    seachEnterFun(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      var val = e.target.value;
      if (keyCode == 13 && val) {
        this.keywords = val;
        this.getTreList();
      }
    },
    chick(e) {
      console.log("e:" + JSON.stringify(e));
      if (e.isTrusted == true) {
        console.log("this.keywords:" + this.keywords);
        this.getTreList();
      }
    },
    //请求数据
    getTreList(index) {
      this.axios
        .get("/api/nav/menu/info/list", {
          params: {
            navMenuId: this.id,
            page: index,
            limit: "10",
            keywords: this.keywords,
            sidx:"create_time",
          },
        })
        .then((res) => {
          this.list = res.data.page.list;
          this.all = res.data.page.totalPage; //总页数
          this.cur = res.data.page.currPage; //当前页
          this.count = res.data.page.totalCount; //总条数
          // if (this.list.length < 1) {
          //   console.log(111);
          //   this.detailId = this.list[0].id;
          //   this.getDetail(this.detailId);
          // } else {
            console.log(222);
            for (var j = 0; j < this.list.length; j++) {
              //平台资讯显示长度截取
              if (this.list[j].introduce.length > 50) {
                this.list[j].introduce =
                  this.list[j].introduce.slice(0, 50) + "...";
              }
            }
          // }
        })
        .catch((err) => {
          console.log(333);

          console.log(err);
        });
    },
    getDetail() {
      console.log(444);
      this.axios
        .get("/api/nav/menu/info/info/" + this.detailId)
        .then((res) => {
          this.detailList = res.data.info;
          this.detailList.particulars = this.detailList.particulars.replace(
            "/",
            this.axios.defaults.baseURL + "/"
          );
          this.aa = this.detailList.particulars;
          var iframe = document.getElementById("particular");
          iframe.contentWindow.document.write(this.aa);
          this.timer = setInterval(() => {
            this.getDHeigh();
          }, 1000);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getDHeigh() {
      console.log(666);
      this.$nextTick(function () {
        //或者Vue.nextTick
        //容器自适应高度
        var iframe = document.getElementById("particular");
        iframe.height =
          iframe.contentWindow.document.documentElement.scrollHeight; //+200;
        if (oldh > 0 && oldh == iframe.height) {
          clearInterval(this.timer); //高度不再增长后退出
        }
        oldh = iframe.height;
      });
    },
    //分页
    btnClick: function (data) {
      //页码点击事件
      if (data != this.cur) {
        this.cur = data;
      }
      //根据点击页数请求数据
      this.getTreList(this.cur.toString());
    },
    pageClick: function () {
      //根据点击页数请求数据
      this.getTreList(this.cur.toString());
    },
  },
  computed: {
    //分页
    indexs: function () {
      var left = 1;
      var right = this.all;
      var ar = [];
      left = ((this.cur - 1) / 5) * 5 + 1;
      right = left + 4;
      if (right > this.all) right = this.all;
      while (left <= right) {
        ar.push(left);
        left++;
      }
      return ar;
    },
  },
};
</script>

<style  scoped>
.trends-recommend {
  margin: 750px auto;
  width: 1286px;
  position: absloute;
  height: 100%;
  padding-bottom: 100px;
  margin-bottom: 0px;
}
.trends-recommend .recom-center {
  width: 1286px;
  padding-top: 30px;
}
.recom-center .center-field {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(175, 175, 175);
}
.recom-body {
  margin-top: 700px;
}
.recom-center .ul1 {
  border-top: solid 1px gainsboro;
}
.recom-center .ul1 .li1 {
  list-style: none;
  width: 100%;
  height: 200px;
  margin-top: 20px;
  border-bottom: solid 1px gainsboro;
}
.center-img img {
  width: 280px;
  height: 180px;
  float: left;
}
.image {
  float: left;
}
.center-name {
  width: 300px;
  margin-left: 300px;
}
.center-name p {
  padding-bottom: 10px;
}
.center-name .name {
  /* color: rgb(95, 91, 151); */
  color: #208bcf;
  font-size: 19px;
  font-weight: 600;
}
.center-name .prize {
  color: rgb(132, 131, 134);
  font-size: 17px;
}
.product-about {
  cursor: pointer;
}
.center-name .product-about .center-more {
  color: rgb(112, 112, 228);
  font-size: 18px;
}
.center-more {
  width: 60px;
  color: rgb(112, 112, 228);
}
.center-name .center-more img {
  width: 16px;
  height: 16px;
  float: right;
}
.center-img .date {
  float: right;
  margin-top: -80px;
  font-size: 19px;
}

.page-bar {
  width: 100%;
  margin: auto;
  text-align: center;
}
.page-bar .page-barson {
  text-align: center;
  color: #738289;
}
.page-bar ul {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.page-bar li {
  list-style: none;
}
.page-bar li:first-child > a {
  margin-left: 0px;
}
.page-bar a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.page-bar a:hover {
  background-color: #208bcf;
}
.page-bar a.banclick {
  cursor: not-allowed;
  display: none;
}

.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #208bcf;
  border-color: #271b5c;
}
.dedetail {
  margin: 0 auto;
  margin-bottom: 0px;
  width: 1286px;
}
.dedetail .newname {
  padding-top: 60px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 30px;
  color: black;
}
.center-search {
  padding-bottom: 10px;
  padding-top: 30px;
  height: 40px;
  z-index: 1;
  margin-bottom: 20px;
  /* border-bottom: solid 1px rgb(36, 34, 34); */
}
.center-search .search-name {
  color: #208bcf;
  margin-bottom: -30px;
  font-weight: bold;
}
.center-search input {
  background: #fff no-repeat 13px 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU5NEY0RTlFMTA4NzExRTM5RTEzQkFBQzMyRjkyQzVBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU5NEY0RTlGMTA4NzExRTM5RTEzQkFBQzMyRjkyQzVBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTk0RjRFOUMxMDg3MTFFMzlFMTNCQUFDMzJGOTJDNUEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTk0RjRFOUQxMDg3MTFFMzlFMTNCQUFDMzJGOTJDNUEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4DjA/RAAABK0lEQVR42pTSQUdEURjG8dOY0TqmPkGmRcqYD9CmzZAWJRHVRIa0iFYtM6uofYaiEW2SRJtEi9YxIklp07ZkWswu0v/wnByve7vm5ee8M+85zz1jbt9Os+WiGkYdYxjCOx5wgFeXUHmtBSzpcCGa+5BJTCjEP+0nKWAT8xqe4ArPGEEVC1hHEbs2oBwdXkM7mj/JLZrad437sCGHOfUtcziutuYu2v8XUFF/4f6vMK/YgAH1HxkBYV60AR31gxkBYd6xAeF3VzMCwvzOBpypX8V4yuFRzX2d2gD/l5yjH4fYQEnzkj4fae5rJulF2sMXVrAsaTWttRFu4Osb+1jEDT71/ZveyhouTch2fINQL9hKefKjuYFfuznXWzXMTabyrvfyIV3M4vhXgAEAUMs7K0J9UJAAAAAASUVORK5CYII=);
  border: 1px solid #ddd;
  /* margin-left: 80px; */
  /* margin-top: -60px; */
  line-height: 19px;
  padding: 12px 0;
  /* width:  -webkit-calc(100% - 170px); */
  width: 400px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* box-shadow: 0 2px 8px #212068 inset; */
  text-align: left;
  font-size: 14px;
  font-family: inherit;
  color: #738289;
  font-weight: bold;
  outline: none;
  text-indent: 40px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #bdb8b8;
}
.center-search button {
  background-color: #208bcf;
  height: 45px;
  width: 80px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
}
.page-barson {
  text-align: center;
  color: #738289ab;
}
</style>