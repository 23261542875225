<template>
  <div class="trends-pageImg" :style="trendspageImg">
    <!-- <div class="pageImg-info">
      <p class="chinese1">掌握更多行业信息</p>
      <p class="chinese2">产品信息产销</p>
    </div>
    <p class="chinese3"></p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      listimg: [],
      coverImg: "",
      trendspageImg: {
        backgroundImage: "",
      },
      id: "",
    };
  },
  props: ["msg"],
  created() {
    this.id = this.$route.params.id;
    this.getHpageImgList(); //生命周期创建时调用axios函数
    if (this.$route.params) {
      this.id = this.$route.params.id;
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.params.id != from.params.id) {
        this.id = to.params.id;
        this.getHpageImgList(); //生命周期创建时调用axios函数
      }
    },
  },
  methods: {
    getHpageImgList() {
      var id = this.$route.params.id;
      console.log("id:" + id);
      this.axios
        .get("/api/nav/menu/info/" + id)
        .then((re) => {
          this.coverImg = re.data.info.coverImg;
          this.trendspageImg.backgroundImage =
            "url(" + this.axios.defaults.baseURL + this.coverImg + ")";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.trends-pageImg {
  width: 100%;
  position: absolute;
  top: 150px;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  height: 700px;
  /* background-image: url("../../assets/img/new1.png"); */
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  /* text-align: left; */
  overflow: hidden;
}
.trends-pageImg .pageImg-info {
  width: 60%;
  margin: 0 auto;
  margin-top: 208px;
  /* margin-left: ; */
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* Khtml内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

.trends-pageImg .chinese1 {
  color: rgb(253, 253, 253);
  font-size: 50px;
  font-weight: 600;
  float: left;
  margin-left: -20px;
  margin-top: 53px;
}
.trends-pageImg .chinese2 {
  color: rgb(253, 253, 253);
  font-size: 18px;
  float: left;
  margin-left: -400px;
  margin-top: 143px;
}
.trends-pageImg .chinese3 {
  background-color: red;
  height: 5px;
  width: 90px;
  margin-left: 287px;
  margin-top: 400px;
}
</style>