<template>
  <div class="trends">
    <trends-img></trends-img>
    <trends-recomMend></trends-recomMend>
  </div>
</template>

<script>
import TrendsImg from "@/components/Trends/pageImg.vue";
import TrendsRecomMend from "@/components/Trends/recomMend.vue";
export default {
  name: "Trends",
  data() {
    return {};
  },
  components: {
    TrendsImg,
    TrendsRecomMend,
  },
};
</script>
<style scoped>
.trends {
  width: 100%;
}
</style> 